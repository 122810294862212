import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import 'ress';
import media from "styled-media-query";
import { connect } from "react-redux"
import { updateWinSize } from "../../actions/winsize";
import Head  from '@components/common/head';

import { color, breakpoint, font, size } from "@src/settings";

type Props = {
	baseBgColor?: string,
	lang: "ja" | "en",
	winSize: Array<number>,
	isMenuOpen: boolean,
}
const Layout: React.FC<Props> = (props) => {
	useEffect(() => {
		props.updateWinSize();
		window.addEventListener('resize', props.updateWinSize);
	},[]);
	return (
		<>
			<Head/>
			<GlobalStyle
				baseBgColor={ props.baseBgColor }
				lang={ props.lang }
				isMenuOpen={ props.isMenuOpen }
			/>
			<Cont
				className={ props.className }
				winW={ props.winSize[0] }
			>	
				{ props.children }
			</Cont>
		</>
	)
}
Layout.defaultProps = {
	baseBgColor: "transparent",
}

const GlobalStyle = createGlobalStyle`
	@charset "UTF-8";
	html, body {
		font-family:${ props => props.lang == "ja" ? font.base : font.en };
		line-height: 1.5;
		color:${ color.txt };
		-webkit-text-size-adjust: 100%;
		font-feature-settings: "palt" 1;
		-webkit-font-feature-settings: "palt" 1;
		letter-spacing: 0.08em;
		background-color: ${ props => props.baseBgColor };

		@media screen and (min-width: 1200px){
			/*画面幅1500pxまで画面幅に応じて+3pxまで大きくなる*/
			font-size: calc(12/16*100% + 2 * (100vw - 1200px)/300);
		}
		@media screen and (min-width: 1000px) and (max-width: 1199px){
			/*1000px幅の時に10pxになる*/
			font-size: calc(1vw);
		}
		@media screen and (max-width: 999px){
			/* IEで10pxにならないための処置、計算結果は62.5%*/ 
			font-size: calc(10em / 16);
		}
	}
	body{
		overflow: ${ props => props.isMenuOpen ? 'hidden':'' }
	}
	li{
		list-style: none;
	}
	a{
		color: inherit;
		text-decoration: none;
	}
	.grecaptcha-badge{
		visibility: hidden;
	}
`

const Cont = styled.div`
	position: relative;
	padding-top: 0;
	${ media.lessThan(`${ breakpoint.tb }px`)`
		padding-top: ${size.headerH_sp/10 }rem;
	`}
`;

//store.winSizeの更新をこのコンポーネントで行う
const mapStateToProps = (state,props) => ({
	isMenuOpen : state.spMenuReducer.isMenuOpen,
	winSize: state.winsizeReducer.winSize,
	lang: state.langReducer.language,
})

const mapDispatchToProps = dispatch => ({
	updateWinSize: () => dispatch(updateWinSize(getWinSize())),
})
const	getWinSize = () => {
	return [window.innerWidth, window.innerHeight];
}

export default connect(mapStateToProps,mapDispatchToProps)(Layout)
