import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { color, breakpoint, mixin } from "@src/settings";

const PageIntro = (props) => (
	<Cont className={ props.className }>
		<div className="container">
			<h2>{ props.title }</h2>
			<p>{ props.subtitle }</p>
		</div>
	</Cont>
);

export default PageIntro;

const Cont = styled.div`
	background: #FFF;
	padding: 6rem 0;

	& > .container{
		${ mixin.baseWidthSet }
		& > h2 {
			font-size: 2rem;
			font-weight: bold;
			text-align: justify;
		}
		& > p {
			font-size: 1.6rem;
			line-height: 1.8;
			margin-top:1rem;
		}
	}

	${ media.lessThan(`${ breakpoint.tb }px`)`
		padding: 6rem 0;
	`}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		padding: 3.5rem 0 3rem;
		& > .container{
			& > h2{
				font-size: 1.8rem;
			}
			& > p{
				line-height: 1.7;
			}
		}
	`}
`;

