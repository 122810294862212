import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { color, breakpoint, mixin } from '@src/settings';

export const Cont = styled.section`
	background: #FFF;
	padding: 8.2rem 0;
	z-index: 20;
`
export const Frame = styled.div`
    display:flex;
		${ mixin.baseWidthSet }
	& > * {
		flex: 50% 1 1;
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		flex-direction: column;
		align-items: center;
		& > * {
			flex: auto 1 1;
		}
	`}
`

export const Title = styled.div`
	align-self: flex-start;
	position: relative;
	padding-bottom: 4rem;
	&:after{
		content:"";
		width: 2.5rem;
		height: 0.2rem;
		background-color: #000;
		position: absolute;
		bottom: 0;
		left: 0;
	}
	& > h2{
		& > svg{
			width: 15.7rem;
			height:auto;
		}
	}
	& > p{
		margin-top: 1.5rem;
		font-size: 1.5rem;
		line-height: 1;
		color: ${color.txtLight} ;
		position:relative;
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 2.5rem;
		margin-bottom: 3rem;
		p{
			&:after{
				left: 50%;
				transform: translateX(-50%);
			}
		}
	`}
`

export const Txts = styled.div`
	& > h3{
		font-size: 1.7rem;
		font-weight: bold;
	}
	& > p{
		font-size: 1.4rem;
		line-height: 1.8;
		text-align: left;
		margin-top: 1rem;
		color: ${color.txtLight} ;
	}
	& > .btn{
		margin-top: 2.5rem;
		width:22rem;
	}
	${media.lessThan( `${ breakpoint.sp }px` )`
		display: flex;
		flex-direction: column;
		aling-items: flex-start;	
		& > .btn{
			align-self: center;
			width:100%;
		}
	`}
`
