import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

const Head = props => {
	return (
		<Helmet>
			<title>{ props.title }</title>
			${ props.lang == "ja" ? (
			<link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap" rel="stylesheet" />
			) : (
			<link href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap" rel="stylesheet" />
			)}
		</Helmet>
	)
}

Head.defaultProps = {
	title: "website of LSF Technology",
}

const mapStateToProps = state => ({
	lang: state.langReducer.language,
})
export default connect(mapStateToProps)(Head);
