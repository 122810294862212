import React from 'react';
import styled from 'styled-components';

export const Contact = props => (
	<svg { ...props } viewBox="0 0 156 21.8">
		<g>
			<path d="M11.2,21.9a12.169,12.169,0,0,1-4.3-.8,10.213,10.213,0,0,1-3.4-2.3,9.49,9.49,0,0,1-2.2-3.4,11.623,11.623,0,0,1-.8-4.2V11a11.623,11.623,0,0,1,.8-4.2A11.525,11.525,0,0,1,3.5,3.3,9.7,9.7,0,0,1,7,1,10.489,10.489,0,0,1,11.4.1a21.3,21.3,0,0,1,2.7.2,8.083,8.083,0,0,1,2.2.7,7.677,7.677,0,0,1,1.8,1,10.1,10.1,0,0,1,1.6,1.3L16.8,6.7A12.838,12.838,0,0,0,14.2,5a6.839,6.839,0,0,0-2.9-.6,6.042,6.042,0,0,0-2.4.5A5.223,5.223,0,0,0,7,6.3,5.71,5.71,0,0,0,5.7,8.4a6.9,6.9,0,0,0-.4,2.5V11a5.753,5.753,0,0,0,.5,2.6A4.451,4.451,0,0,0,7,15.7a5.223,5.223,0,0,0,1.9,1.4,6.551,6.551,0,0,0,2.5.5,5.559,5.559,0,0,0,3-.7,9.775,9.775,0,0,0,2.5-1.8l2.9,3a12.878,12.878,0,0,1-1.7,1.6,7.607,7.607,0,0,1-1.9,1.2,12.344,12.344,0,0,1-2.3.8,19.938,19.938,0,0,1-2.7.2Z"/>
			<path d="M34.2,21.9a11.3,11.3,0,0,1-4.5-.9,10.654,10.654,0,0,1-3.5-2.3,9.756,9.756,0,0,1-2.3-3.4,9.9,9.9,0,0,1-.9-4.2V11a11.623,11.623,0,0,1,.8-4.2,11.809,11.809,0,0,1,2.3-3.5A10.144,10.144,0,0,1,29.6,1a11.7,11.7,0,0,1,9,0,10.654,10.654,0,0,1,3.5,2.3,9.756,9.756,0,0,1,2.3,3.4,11.623,11.623,0,0,1,.8,4.2V11a11.623,11.623,0,0,1-.8,4.2,11.809,11.809,0,0,1-2.3,3.5A10.144,10.144,0,0,1,38.6,21a10.489,10.489,0,0,1-4.4.9Zm0-4.3a7.512,7.512,0,0,0,2.6-.5,5.579,5.579,0,0,0,2-1.4,7.434,7.434,0,0,0,1.3-2.1,6.963,6.963,0,0,0,.5-2.5V11a6.551,6.551,0,0,0-.5-2.5,7.434,7.434,0,0,0-1.3-2.1,5.579,5.579,0,0,0-2-1.4,6.963,6.963,0,0,0-2.5-.5,7.512,7.512,0,0,0-2.6.5,5.579,5.579,0,0,0-2,1.4,5.71,5.71,0,0,0-1.3,2.1,6.963,6.963,0,0,0-.5,2.5,7.512,7.512,0,0,0,.5,2.6,7.434,7.434,0,0,0,1.3,2.1,5.579,5.579,0,0,0,2,1.4,6.963,6.963,0,0,0,2.5.5ZM50.5.5h4.3l9.8,12.9V.5h4.6v21H65.3L55.1,8.2V21.6H50.5Z"/>
			<path d="M80.2,4.8H73.8V.5H91.2V4.8H84.8V21.5H80.2ZM100.9.3h4.3l9,21.2h-4.8l-1.9-4.7H98.6l-1.9,4.7H92Zm4.9,12.4L103,5.9l-2.8,6.8h5.6Zm21.5,9.2a12.169,12.169,0,0,1-4.3-.8,10.212,10.212,0,0,1-3.4-2.3,9.49,9.49,0,0,1-2.2-3.4,11.623,11.623,0,0,1-.8-4.2V11a11.34,11.34,0,0,1,3-7.7A8.568,8.568,0,0,1,123.1,1a12.783,12.783,0,0,1,4.4-.9,21.3,21.3,0,0,1,2.7.2,14.161,14.161,0,0,1,2.2.7,7.677,7.677,0,0,1,1.8,1,10.1,10.1,0,0,1,1.6,1.3l-2.9,3.4A16.359,16.359,0,0,0,130.3,5a6.839,6.839,0,0,0-2.9-.6,6.042,6.042,0,0,0-2.4.5,5.223,5.223,0,0,0-1.9,1.4,7.434,7.434,0,0,0-1.3,2.1,6.963,6.963,0,0,0-.5,2.5V11a5.753,5.753,0,0,0,.5,2.6,7.194,7.194,0,0,0,1.2,2.1,5.223,5.223,0,0,0,1.9,1.4,6.551,6.551,0,0,0,2.5.5,5.559,5.559,0,0,0,3-.7,9.775,9.775,0,0,0,2.5-1.8l2.9,3a12.879,12.879,0,0,1-1.7,1.6,7.607,7.607,0,0,1-1.9,1.2,12.344,12.344,0,0,1-2.3.8,18.365,18.365,0,0,1-2.6.2ZM145.5,4.8h-6.4V.5h17.4V4.8h-6.4V21.5h-4.6Z"/>
		</g>
	</svg>
)
